import { Component } from '@angular/core';
import { AuthenticationService } from 'src/auth/auth_service';

const DEFAULT_USER_ICON = "https://material.io/tools/icons/static/icons/baseline-person-24px.svg";

@Component({
  selector: 'user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss']
})
export class UserIconComponent {

  public user: string;
  
  public userIconUrl: string = DEFAULT_USER_ICON;

  constructor(private readonly authService: AuthenticationService) {
    this.authService.getUserDetails().subscribe(userDetails => {
      if (userDetails) {
        this.user = userDetails.displayName;
        this.userIconUrl = userDetails.photoUrl;
      } else {
        this.user = undefined;
        this.userIconUrl = DEFAULT_USER_ICON;
      }
    });
  }

}
