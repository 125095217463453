import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AboutComponent } from 'src/home/about/about.component';
import { ContactComponent } from 'src/home/contact/contact.component';
import { ProductComponent } from 'src/home/product/product.component';
import { PricingComponent } from 'src/home/pricing/pricing.component';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ProductComponent,
    PricingComponent,
  ],
  providers: [],
  bootstrap: [HomeComponent, AboutComponent, ContactComponent, ProductComponent, PricingComponent],
  exports: [HomeComponent, AboutComponent, ContactComponent, ProductComponent, PricingComponent]
})
export class HomeModule { }
