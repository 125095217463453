import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from 'src/home/home.component';
import { AboutComponent } from 'src/home/about/about.component';
import { ContactComponent } from 'src/home/contact/contact.component';
import { PricingComponent } from 'src/home/pricing/pricing.component';
import { ProductComponent } from 'src/home/product/product.component';

import { ForumComponent } from 'src/forum/forum.component';

import { PanelComponent } from 'src/panel/panel.component';
import { DashboardComponent } from 'src/panel/dashboard/dashboard.component';
import { ProcessComponent } from 'src/panel/process/process.component';

import { DocumentComponent } from 'src/doc/doc.component';

const routes: Routes = [
  { path: 'forum', component: ForumComponent },
  { 
    path: 'panel', 
    component: PanelComponent, 
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'process', component: ProcessComponent },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ] 
  },
  { path: 'document', redirectTo: 'doc', pathMatch: 'full' },
  { path: 'doc', component: DocumentComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'product', component: ProductComponent },
  { path: '', component: HomeComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
