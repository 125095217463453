import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { RouterModule } from '@angular/router';

import {AuthenticationModule} from 'src/auth/auth.module';

import { MenuComponent } from 'src/menu/menu.component';
import { UserIconComponent } from 'src/common/user-icon/user-icon.component';

@NgModule({
  declarations: [
    MenuComponent,
    UserIconComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    AuthenticationModule,
  ],
  providers: [],
  bootstrap: [MenuComponent],
  exports: [MenuComponent]
})
export class MenuModule { }
