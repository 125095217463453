import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MenuModule } from 'src/menu/menu.module';
import { HomeModule } from 'src/home/home.module';
import { ForumModule } from 'src/forum/forum.module';
import { PanelModule } from 'src/panel/panel.module';
import { DocumentModule } from 'src/doc/doc.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MenuModule,
    HomeModule,
    ForumModule,
    PanelModule,
    DocumentModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
