import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ForumComponent } from './forum.component';

@NgModule({
  declarations: [
    ForumComponent,
  ],
  providers: [],
  bootstrap: [ForumComponent],
  exports: [ForumComponent]
})
export class ForumModule { }
