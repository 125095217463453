import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { DocumentComponent } from './doc.component';

@NgModule({
  declarations: [
    DocumentComponent,
  ],
  providers: [],
  bootstrap: [DocumentComponent],
  exports: [DocumentComponent]
})
export class DocumentModule { }
