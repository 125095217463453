import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthenticationService } from 'src/auth/auth_service';

const PANEL_URL = "/panel";

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
  public name = "Test of Menu Bar";

  public signedIn: boolean = undefined;
  public displayDropdown = false;
  public displayAuthOverlay = false;
  public controlPanelMode = false;

  constructor(
    private readonly router: Router, 
    private readonly authService: AuthenticationService) {

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          console.log("nav end: " + JSON.stringify(event));
          if (event.urlAfterRedirects.startsWith(PANEL_URL)) {
            this.controlPanelMode = true;
          } else {
            this.controlPanelMode = false;
          }
        }
      });

      this.authService.getUserDetails().subscribe(userDetails => {
        if (userDetails) {
          this.signedIn = true;
        } else {
          this.signedIn = false;
        }
      });
  }

  public signIn(): void {
    this.authService.prepareSignIn();
    this.displayAuthOverlay = true;
  }

  public cancelSignIn(): void {
    this.displayAuthOverlay = false;
  }

  public signOut(): void {
    this.authService.signOut();
  }

  public clickUserIcon($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.displayDropdown = !this.displayDropdown
  }

  @HostListener('document:click', ['$event']) clickedOutside($event){
    this.displayDropdown = false;
  }
}
