import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import * as firebase from 'firebase'
import * as firebaseui from 'firebaseui'

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {

    private app: firebase.app.App;

    private authUI: firebaseui.auth.AuthUI;

    private userDetails: BehaviorSubject<UserDetails|undefined> = new BehaviorSubject(undefined);;

    constructor() {
        this.initializeApp();
        this.registerAuthEvents();
    }

    private initializeApp(): void {
        if (!this.app) {
            var config = {
                apiKey: "AIzaSyCrxJyX7wQ5ed5UQV70fbbKQWdUQqS2T9Y",
                authDomain: "spedir-gelato.firebaseapp.com",
                databaseURL: "https://spedir-gelato.firebaseio.com",
                projectId: "spedir-gelato",
                storageBucket: "spedir-gelato.appspot.com",
                messagingSenderId: "1020941862165"
            };
            this.app = firebase.initializeApp(config);
        }
    }

    private registerAuthEvents(): void {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken().then((accessToken) => {
                    const userDetails = {
                        displayName: user.displayName,
                        email: user.email,
                        photoUrl: user.photoURL,
                        accessToken: accessToken,
                    };
                    this.userDetails.next(userDetails);
                });
                if (this.authUI) {
                    this.authUI.reset();
                }
            } else {
                this.userDetails.next(undefined);
                this.prepareSignIn();
            }
        });
    }

    public prepareSignIn(): void {      
        if (!this.authUI) {
            // FirebaseUI config.
            var uiConfig = {
                signInSuccessUrl: 'panel',
                signInOptions: [
                    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                    firebase.auth.EmailAuthProvider.PROVIDER_ID,
                ],
            };
            this.authUI = new firebaseui.auth.AuthUI(firebase.auth());
        }
        this.authUI.start('#auth-container', uiConfig);
    }

    public signOut(): void {
        firebase.auth().signOut();
    }

    public getUserDetails(): Observable<UserDetails|undefined> {
        return this.userDetails.asObservable();
    }
}

export declare interface UserDetails {
    displayName: string;
    email: string;
    photoUrl: string;
    accessToken: string;
}