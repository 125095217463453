import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from 'src/app/app-routing.module';

import { PanelComponent } from './panel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProcessComponent } from './process/process.component';

@NgModule({
  declarations: [
    PanelComponent,
    DashboardComponent,
    ProcessComponent,
  ],
  providers: [],
  imports: [
    AppRoutingModule,
  ],
  bootstrap: [PanelComponent, DashboardComponent, ProcessComponent],
  exports: [PanelComponent, DashboardComponent, ProcessComponent]
})
export class PanelModule { }
